.pipeline-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  padding: 0.5rem;
  margin: 0.5rem 0;
  border-radius: 5px;
  background-color: #f1f1f1;
}